import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const HighLights = ({ highlights, className = '' }) => {
  return (
    <ul className={`highlights ${className}`}>
      {highlights.map((item, i) => {
        return (
          <li
            key={`infoList_${i}`}
            style={
              item.node.frontmatter.image
                ? {
                    backgroundImage: `url(${
                      item.node.frontmatter.image.childImageSharp.fluid.src
                    })`
                  }
                : {}
            }
          >
            <Link to={item.node.fields.slug}>
              <span>{item.node.frontmatter.title}</span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default HighLights;
